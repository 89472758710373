import { memo, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Chip,
  useTheme,
  Skeleton,
  Stack,
  Tooltip
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import { isEqual } from 'lodash';

import { useAppServices } from 'hooks/useAppServices';
import { FileData, FileMetadata } from 'services/api/models';
import { downloadFile } from 'util/files';
import EditDocumentMetadataPopup from 'components/modal/EditDocumentMetadataPopup/EditDocumentMetadataPopup';
import { AlertMessage } from 'contexts/AlertContext';
import { Trans, useTranslation } from 'react-i18next';
import DeletePopup from 'components/modal/DeletePopup/DeletePopup';

interface ActionBarProps {
  fileData: FileData;
  onEditTags: (fileData: FileData) => void;
  onDownload: (fileData: FileData) => void;
  onDelete: (fileData: FileData) => void;
}

const DocumentActionBar: React.FC<ActionBarProps> = (props: ActionBarProps) => {
  const { fileData, onEditTags, onDownload, onDelete } = props;

  /*
   * ************** Providers **************
   */
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'documentStore.viewer.actionBar'
  });

  /*
   * ************** Render **************
   */
  return (
    <Stack direction="row" spacing={0.1}>
      <Tooltip title={t('editTagsTooltip')}>
        <IconButton
          edge="end"
          aria-label="edit-tags"
          onClick={() => onEditTags(fileData)}
          sx={{
            '&::after': {
              content: '""',
              position: 'absolute',
              height: '80%',
              display: 'block',
              left: -3,
              width: '1px',
              bgcolor: 'divider'
            }
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('downloadTooltip')}>
        <IconButton
          edge="end"
          aria-label="download"
          onClick={() => onDownload(fileData)}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('deleteTooltip')}>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onDelete(fileData)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

interface DocumentStoreViewerProps {
  files: FileData[];
  tags: string[];
  onUpdate: (queryKeys?: string[], alert?: AlertMessage) => void;
  onError: (error: unknown) => void;
}

const DocumentStoreViewer: React.FC<DocumentStoreViewerProps> = memo(
  (props: DocumentStoreViewerProps) => {
    const { files, tags, onUpdate, onError } = props;

    /*
     * ************** Providers **************
     */
    const theme = useTheme();
    const { dataService } = useAppServices();
    const { t } = useTranslation('knowledgeBase', {
      keyPrefix: 'documentStore.viewer'
    });

    /*
     * ************** State Vars **************
     */
    const [selectedFile, setSelectedFile] = useState<FileData>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);

    /*
     * ************** Hooks **************
     */
    const updateTagsMutation = useMutation({
      mutationFn: (newTags: { fileName: string; metadata: FileMetadata }) =>
        dataService.updateFileTags(newTags.fileName, newTags.metadata.tags),
      onSuccess: () =>
        onUpdate(['files'], {
          severity: 'info',
          message: t('tagsUpdated')
        }),
      onError: onError
    });

    const deleteMutation = useMutation({
      mutationFn: (fileName: string) => dataService.deleteFile(fileName),
      onSuccess: () =>
        onUpdate(['files'], {
          severity: 'info',
          message: t('fileDeleted')
        }),
      onError: onError
    });

    /*
     * ************** Helper Functions **************
     */
    const handleDelete = (fileData: FileData) => {
      setSelectedFile(fileData);
      setShowDeleteDialog(true);
    };

    const handleDownload = async (fileData: FileData) => {
      setSelectedFile(fileData);
      try {
        const file = await dataService.fetchFile(fileData.name);
        downloadFile(fileData.name, file);
      } catch (_error: unknown) {
        const error = _error as Error;
        setSelectedFile(undefined);
        onError(error);
      }
    };

    const handleEditTags = (fileData: FileData) => {
      setSelectedFile(fileData);
      setShowEditDialog(true);
    };

    /*
     * ************** Render **************
     */
    if (deleteMutation.isLoading) {
      return (
        <Grid container sx={{ my: theme.spacing(5) }}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: {
                  lg: '50%',
                  md: '50%',
                  sm: '50%',
                  xs: '100%'
                },
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <Typography variant="h2">{t('title')}</Typography>
              <Skeleton
                variant="rounded"
                animation="wave"
                height={150}
                width="100%"
              />
            </Box>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Grid container sx={{ my: theme.spacing(5) }}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: {
                  lg: '50%',
                  md: '50%',
                  sm: '50%',
                  xs: '100%'
                },
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <Typography variant="h2">{t('title')}</Typography>
              <Typography variant="body2">{t('description')}</Typography>
            </Box>
            <List
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '8px',
                bgColor: theme.palette.background.paper,
                mt: 3
              }}
            >
              {files.map((fileData) => (
                <ListItem
                  key={fileData.name}
                  secondaryAction={
                    <DocumentActionBar
                      fileData={fileData}
                      onDelete={handleDelete}
                      onDownload={handleDownload}
                      onEditTags={handleEditTags}
                    />
                  }
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Grid container spacing={1}>
                        <Grid item lg={5} md={12}>
                          <Tooltip
                            title={fileData.name}
                            enterDelay={500}
                            enterNextDelay={500}
                            placement="bottom-start"
                          >
                            <Box
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {fileData.name}
                            </Box>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          {fileData.tags.map((tag, index) => (
                            <Chip
                              key={index}
                              label={tag}
                              sx={{
                                backgroundColor:
                                  theme.palette.mode === 'light'
                                    ? 'rgba(157, 205, 255, 0.49)'
                                    : 'rgba(26, 138,255, 0.49)',
                                mr: '4px'
                              }}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        {selectedFile && (
          <>
            <DeletePopup
              open={showDeleteDialog}
              title={t('popup.delete.title')}
              message={
                <Trans>
                  {t('popup.delete.message')} &apos;
                  {{ fileName: selectedFile.name }}&apos;?
                </Trans>
              }
              onConfirm={() => {
                setShowDeleteDialog(false);
                deleteMutation.mutate(selectedFile.name);
              }}
              onCancel={() => setShowDeleteDialog(false)}
            />
            <EditDocumentMetadataPopup
              open={showEditDialog}
              metadata={{
                tags: selectedFile?.tags
              }}
              tags={tags}
              onSave={(metadata) => {
                setShowEditDialog(false);
                updateTagsMutation.mutate({
                  fileName: selectedFile?.name ?? '',
                  metadata
                });
              }}
              onClose={() => setShowEditDialog(false)}
            />
          </>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    const prevFileNames = prevProps.files.map(({ name }) => name);
    const nextFileNames = nextProps.files.map(({ name }) => name);
    return isEqual(prevFileNames, nextFileNames);
  }
);

DocumentStoreViewer.displayName = 'DocumentStoreViewer';

export default DocumentStoreViewer;
