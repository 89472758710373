import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Box } from '@mui/material';

import '@cyntler/react-doc-viewer/dist/index.css';

export interface FileViewerProps {
  file?: {
    url?: string;
    name?: string;
    label?: string;
    page?: number;
  };
}

export const FileViewer: React.FC<FileViewerProps> = ({
  file
}: FileViewerProps) => {
  const fileUrl = file?.url || '';
  const fileName = file?.name || '';

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'grid'
      }}
    >
      <DocViewer
        documents={[
          {
            uri: fileUrl,
            fileName
          }
        ]}
        pluginRenderers={DocViewerRenderers}
        config={{
          pdfVerticalScrollByDefault: true
        }}
      />
    </Box>
  );
};
