import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useAppServices } from 'hooks/useAppServices';
import { FileViewer } from 'components/FileViewer/FileViewer';
import { OfficeApp, openOfficeFile } from 'util/files';
import { OpenInOfficeContainer } from 'components/ui/container';
import { Citation } from 'components/util/chatMessageParser';

interface PreviewCitationPanelProps {
  open: boolean;
  citation?: Citation;
  onClose: () => void;
}

const PreviewCitationPanel: React.FC<PreviewCitationPanelProps> = (
  props: PreviewCitationPanelProps
) => {
  const { open, citation, onClose } = props;

  /*
   * ************** Providers **************
   */
  const { dataService } = useAppServices();
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'citationPreviewPanel'
  });

  /*
   * ************** State Vars *********************
   */
  const fileExt = (citation?.fileName ?? '')
    .split('.')
    .pop()
    ?.toLowerCase()
    .trim();
  const isOfficeType = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
    fileExt ?? ''
  );

  /*
   * ************** Hooks *********************
   */
  const { data, isLoading, isError } = useQuery({
    queryKey: ['citation', citation?.fileName],
    queryFn: async () => dataService.fetchFile(citation!.fileName),
    staleTime: Number.POSITIVE_INFINITY,
    enabled: citation !== undefined
  });

  /*
   * ************** Helper Functions **************
   */
  const handleOpenWithOfficeClicked = () => {
    if (data) {
      let officeApp: OfficeApp;
      switch (fileExt) {
        case 'doc':
        case 'docx':
          officeApp = OfficeApp.Word;
          break;
        case 'ppt':
        case 'pptx':
          officeApp = OfficeApp.Powerpoint;
          break;
        case 'xls':
        case 'xlsx':
          officeApp = OfficeApp.Excel;
          break;
        default:
          throw new Error(`Invalid file type for MS Office: ${fileExt}`);
      }

      openOfficeFile(data, officeApp);
    }
  };

  /*
   * ************** Render **************
   */
  if (isError) {
    return <></>;
  }

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        width: '40vw'
      }}
      PaperProps={{
        sx: {
          width: '40vw'
        },
        style: {
          top: '60px',
          padding: theme.spacing(3),
          border: '1px solid rgba(0, 0, 0, 0.12)',
          boxSizing: 'border-box'
        }
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Box sx={{ mb: theme.spacing(1) }}>
        <IconButton onClick={onClose} sx={{ justifyContent: 'flex-start' }}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Divider />
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          {citation &&
            (isOfficeType ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <OpenInOfficeContainer>
                  <Typography variant="h3">
                    {t('openInOfficeInstruction')}
                  </Typography>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleOpenWithOfficeClicked}
                  >
                    {t('openInOfficeButton')}
                  </Button>
                </OpenInOfficeContainer>
              </Box>
            ) : (
              <FileViewer
                file={{
                  url: URL.createObjectURL(data),
                  name: citation.fileName,
                  label: citation.label
                }}
              />
            ))}
        </>
      )}
    </Drawer>
  );
};

export default PreviewCitationPanel;
