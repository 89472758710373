import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Skeleton,
  Chip,
  Input,
  Checkbox,
  Tooltip,
  IconButton,
  Zoom
} from '@mui/material';
import {
  GridColDef,
  DataGrid,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
  GridCellEditStopParams,
  MuiEvent,
  GridCellEditStopReasons
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';

import { useAppServices } from 'hooks/useAppServices';
import { GroupTag } from 'services/api/models';
import { useAuth } from 'hooks/useAuth';
import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';
import { useTranslation } from 'react-i18next';

type RowData = {
  id: string;
  groupName: string;
  isPublic: boolean;
  tags: string[];
};

type RenderCheckboxProps = GridRenderCellParams<RowData, boolean> & {
  onChange: (row: RowData) => void;
};

const RenderCheckbox: React.FC<RenderCheckboxProps> = (props) => {
  const { row, value, onChange } = props;

  const [checked, setChecked] = useState<boolean>(value ?? false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChange({
      ...row,
      isPublic: e.target.checked
    });
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};

const TagsEditInputCell: React.FC<GridRenderCellParams<RowData, string[]>> = (
  props
) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const theme = useTheme();

  const [newTag, setNewTag] = useState<string>('');

  const handleEnterOrSpacePressed = (event: React.KeyboardEvent<Element>) => {
    if (
      (event.key === 'Enter' && !event.shiftKey) ||
      (event.key === ' ' && !event.shiftKey)
    ) {
      event.preventDefault();
      if (newTag.trim().length > 0) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: [...(value ?? []), newTag]
        });
        setNewTag('');
      }
    }
  };

  const handleTagDeleted = (index: number) => {
    const newTags = value ?? [];
    newTags.splice(index, 1);
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newTags
    });
  };

  return (
    <Box>
      {value
        ?.filter((tag) => tag !== 'public')
        .map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            sx={{
              mx: '2px',
              backgroundColor:
                theme.palette.mode === 'light'
                  ? 'rgba(157, 205, 255, 0.49)'
                  : 'rgba(26, 138,255, 0.49)'
            }}
            onDelete={() => handleTagDeleted(index)}
          />
        ))}
      <Input
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyUp={handleEnterOrSpacePressed}
        size={'small'}
        sx={{ ml: '2px' }}
      />
    </Box>
  );
};

export interface GroupTagsTabProps {
  groupTags: GroupTag[];
  onUpdate: (groupTags: GroupTag[]) => void;
  onError: (error: Error) => void;
}

const GroupTagsTab: React.FC<GroupTagsTabProps> = (props) => {
  const { groupTags, onUpdate, onError } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { aadInfo } = useAuth();
  const { securityService } = useAppServices();
  const { t } = useTranslation('settings', {
    keyPrefix: 'groupTags'
  });

  // Fetch tenant security groups
  const {
    data: tenantGroups,
    error: tenantGroupsError,
    isLoading: tenantGroupsIsLoading,
    isError: tenantGroupsIsError
  } = useQuery({
    queryKey: ['tenantGroups'],
    queryFn: async () =>
      await securityService.getTenantSecurityGroups(aadInfo.tenantId)
  });

  // Populate table once data is available
  useEffect(() => {
    if (tenantGroups) {
      const rows: RowData[] = [];
      for (const tenantGroup of tenantGroups) {
        const groupTag = groupTags.find(
          (groupTag) => groupTag.groupId === tenantGroup.id
        );

        const tags = groupTag ? groupTag.tags : [];
        const isPublic = tags.some((tag) => tag === 'public');
        rows.push({
          id: tenantGroup.id,
          groupName: tenantGroup.displayName,
          isPublic,
          tags
        });
      }

      setRows(rows);
    }
  }, [groupTags, tenantGroups]);

  /*
   * ************** State Vars *********************
   */
  const [rows, setRows] = useState<Array<RowData>>([]);

  /*
   * ************** Helper Functions *********************
   */
  const handleRowUpdated = (rowData: RowData) => {
    const oldTags = groupTags;
    const indexToUpdate = oldTags.findIndex(
      (groupTag) => groupTag.groupId === rowData.id
    );

    const newTags = [...oldTags];

    // Filter out empty tags
    const tags = rowData.tags.filter((tag) => tag && tag.trim().length > 0);

    // Handle the public tag
    if (rowData.isPublic) {
      if (!tags.some((tag) => tag === 'public')) {
        tags.push('public');
      }
    } else {
      if (tags.some((tag) => tag === 'public')) {
        const index = tags.findIndex((tag) => tag === 'public');
        tags.splice(index, 1);
      }
    }

    if (indexToUpdate < 0) {
      // Add a new group to tagged groups
      if (tags.length > 0) {
        newTags.push({
          groupId: rowData.id,
          tags
        });
      }
    } else {
      // Update the tags for the group
      newTags[indexToUpdate] = {
        ...newTags[indexToUpdate],
        tags
      };
    }

    // Only send an update request if the tags have changed
    if (!isEqual(oldTags, newTags)) {
      onUpdate(newTags);
    }
  };

  /*
   * ************** Render *********************
   */
  const columns: GridColDef[] = [
    {
      field: 'groupName',
      headerName: t('columns.groupName'),
      headerClassName: 'group-tag-table-header',
      width: 400
    },
    {
      field: 'isPublic',
      headerClassName: 'group-tag-table-header',
      type: 'boolean',
      width: 200,
      renderHeader: () => (
        <div className="group-tag-table-header">
          {t('columns.isPublic')}
          <Tooltip
            title="Check this box if the group should have access to public company documents"
            placement="top-end"
            TransitionComponent={Zoom}
          >
            <sup>
              <IconButton
                sx={{
                  color:
                    theme.palette.mode === 'light'
                      ? LightModeColorPalette.navMenuBackground
                      : DarkModeColorPalette.navMenuBackground
                }}
              >
                <InfoIcon sx={{ fontSize: '16px' }} fontSize="inherit" />
              </IconButton>
            </sup>
          </Tooltip>
        </div>
      ),
      renderCell: (params) => (
        <RenderCheckbox {...params} onChange={handleRowUpdated} />
      )
    },
    {
      field: 'tags',
      headerName: t('columns.tags'),
      headerClassName: 'group-tag-table-header',
      headerAlign: 'center',
      editable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string[]>) =>
        params.value
          ?.filter((tag) => tag !== 'public')
          .map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              sx={{
                backgroundColor:
                  theme.palette.mode === 'light'
                    ? 'rgba(157, 205, 255, 0.49)'
                    : 'rgba(26, 138,255, 0.49)',
                mx: '2px'
              }}
            />
          )),
      renderEditCell: (params: GridRenderEditCellParams<any, string[]>) => (
        <TagsEditInputCell {...params} />
      )
    }
  ];

  if (tenantGroupsIsLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={theme.spacing(60)}
        />
      </Box>
    );
  }

  if (tenantGroupsIsError) {
    onError(tenantGroupsError as Error);
    return <div></div>;
  }

  return (
    <>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Typography variant="h4">{t('title')}</Typography>
        <Typography sx={{ mt: theme.spacing(1) }} variant="body1">
          {t('instructions')}
        </Typography>
      </Box>

      <Box
        sx={{
          '& .group-tag-table-header': {
            backgroundColor:
              theme.palette.mode === 'light'
                ? LightModeColorPalette.headerBarBackground
                : DarkModeColorPalette.headerBarBackground,
            color:
              theme.palette.mode === 'light'
                ? LightModeColorPalette.textAlternate
                : DarkModeColorPalette.textAlternate,
            fontSize: '1.4vmin'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
            if (params.reason === GridCellEditStopReasons.enterKeyDown) {
              event.defaultMuiPrevented = true;
            }
          }}
          processRowUpdate={(newRow: RowData) => {
            handleRowUpdated(newRow);
            return newRow;
          }}
          disableColumnMenu
          hideFooterSelectedRowCount
          pageSizeOptions={[25]}
          sx={{
            '& .MuiDataGrid-root': {
              borderColor:
                theme.palette.mode === 'light' ? '#c9c9c9' : '#757575'
            },
            '& .MuiDataGrid-cell': {
              backgroundColor:
                theme.palette.mode === 'light' ? '#f8f8f8' : '#171717',
              borderRight: `1px solid ${
                theme.palette.mode === 'light' ? '#c9c9c9' : '#757575'
              }`
            }
          }}
        />
      </Box>
    </>
  );
};

export default GroupTagsTab;
