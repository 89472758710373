import {
  Grid,
  Box,
  Typography,
  Tooltip,
  Zoom,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  useTheme,
  SelectChangeEvent,
  Skeleton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Alert
} from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import InfoIcon from '@mui/icons-material/Info';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useAppServices } from 'hooks/useAppServices';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { AlertMessage } from 'contexts/AlertContext';

// 10 MB max file size
const FILE_SIZE_UNIT = 1000 * 1000;
const MAX_FILE_COUNT = 10;
const MAX_FILE_SIZE = 50 * FILE_SIZE_UNIT;
const MAX_FILE_SIZE_TEXT = '50 MB';

interface DocumentUploaderProps {
  allowedFileTypes: string[];
  tags: string[];
  onUpdate: (queryKeys?: string[], alert?: AlertMessage) => void;
  onError: (error: unknown) => void;
}

const DocumentUploader: React.FC<DocumentUploaderProps> = (
  props: DocumentUploaderProps
) => {
  const { allowedFileTypes, tags, onUpdate, onError } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { dataService } = useAppServices();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'documentStore.uploader'
  });

  /*
   * ************** State Vars **************
   */
  const [showWarningText, setShowWarningText] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileTags, setFileTags] = useState<string[]>([]);
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});

  /*
   * ************** Hooks **************
   */
  const uploadMutation = useMutation({
    mutationFn: async () => {
      if (files) {
        if (files.length > MAX_FILE_COUNT) {
          throw new Error(`${t('maxFilesError')}: ${MAX_FILE_COUNT}`);
        }

        for (const file of files) {
          const fileSize = file.size;
          if (fileSize > MAX_FILE_SIZE) {
            throw new Error(
              `${t('fileSizeError')}: ${
                fileSize * FILE_SIZE_UNIT
              } > ${MAX_FILE_SIZE}`
            );
          }
        }

        dataService.uploadFiles(files, fileTags, descriptions);
      }
    },
    onSuccess: () => {
      setFiles([]);
      setFileTags([]);
      setShowWarningText(false);
      setDescriptions({});
      onUpdate(['files'], {
        severity: 'info',
        message: t('indexingStarted')
      });
    },
    onError: onError
  });

  /*
   * ************** Helper Functions **************
   */
  const handleTagsChanged = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;

    // On autofill we get a stringified value
    const newTags = typeof value === 'string' ? value.split(',') : value;
    setFileTags(newTags);

    newTags.length > 0 ? setShowWarningText(false) : setShowWarningText(true);
  };

  const handleFilesChanged = (files: File[]) => {
    setFiles(files);
    fileTags.length > 0 ? setShowWarningText(false) : setShowWarningText(true);
  };

  const handleFileDeleted = (file: File) => {
    const filesCopy = [...files];
    const indexToDelete = filesCopy.findIndex((f) => f.name === file.name);
    filesCopy.splice(indexToDelete, 1);
    setFiles(filesCopy);

    if (filesCopy.length > 0 && fileTags.length === 0) {
      setShowWarningText(true);
    } else {
      setShowWarningText(false);
    }
  };

  /*
   * ************** Render **************
   */
  if (uploadMutation.isLoading) {
    return (
      <Grid container sx={{ mb: theme.spacing(5) }} spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: {
                lg: '50%',
                md: '50%',
                sm: '50%',
                xs: '100%'
              },
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}
          >
            <Typography variant="h2">{t('title')}</Typography>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={150}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <Typography variant="h2">{t('title')}</Typography>
          <Typography variant="body2">
            {t('instructions')}
            <Tooltip
              title={
                <>
                  <Typography sx={{ fontSize: '0.75rem' }}>{`${t(
                    'instructionsTooltip.maxFiles'
                  )}: ${MAX_FILE_COUNT}.`}</Typography>
                  <Typography sx={{ fontSize: '0.75rem' }}>
                    {`${t(
                      'instructionsTooltip.acceptedFormats'
                    )}: ${allowedFileTypes.join(', ')}`}
                  </Typography>
                </>
              }
              placement="top-end"
              TransitionComponent={Zoom}
            >
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <MuiFileInput
              sx={{ flexGrow: 1 }}
              placeholder={`${t('inputPlaceholder')}: ${MAX_FILE_SIZE_TEXT}`}
              multiple
              value={files}
              onChange={handleFilesChanged}
              clearIconButtonProps={{
                title: `${t('clearButton')}`,
                children: <CloseIcon fontSize="small" />
              }}
              InputProps={{
                inputProps: {
                  accept: `${allowedFileTypes.join(',')}`
                },
                startAdornment: <AttachFileIcon />
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="select-tag-label">{t('tags')}</InputLabel>
              <Select
                labelId="select-tag-label"
                multiple
                value={fileTags}
                onChange={handleTagsChanged}
                input={<OutlinedInput label={t('tags')} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{
                          backgroundColor:
                            theme.palette.mode === 'light'
                              ? 'rgba(157, 205, 255, 0.49)'
                              : 'rgba(26, 138,255, 0.49)',
                          mx: '2px'
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {files.length > 0 && (
          <Grid item md={8}>
            <Box>
              <List>
                {files.map((file, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleFileDeleted(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={`${file.size / 1000} KB`}
                      sx={{ flex: '1 1 40%' }}
                    />
                    {(file.type === 'application/json' ||
                      file.type === 'text/csv') && (
                      <TextField
                        sx={{ flex: '1 1 40%' }}
                        placeholder={t('descriptionInputPlaceholder')}
                        value={descriptions[file.name]}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setDescriptions({
                            ...descriptions,
                            [file.name]: event.target.value
                          })
                        }
                      />
                    )}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={8} sx={{ mt: theme.spacing(2) }}>
          {showWarningText && (
            <Alert severity="warning">
              Uploading a document without tags will assign it to the public
              knowledge base by default
            </Alert>
          )}
          <Box
            component="button"
            sx={{
              backgroundColor: 'info.main',
              padding: '10px 10px',
              mt: '10px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.background.default,
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
              width: {
                md: '15.01%',
                sm: '40%',
                xs: '70%'
              }
            }}
            disabled={files.length === 0}
            onClick={() => uploadMutation.mutate()}
          >
            {t('uploadButton')}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentUploader;
