import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';

import { useAlerts } from 'hooks/useAlerts';
import { useAuth } from 'hooks/useAuth';
import { useAppServices } from 'hooks/useAppServices';

const ProfileInfoTab: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('profile', { keyPrefix: 'tabs.profileInfo' });
  const { sendAlert } = useAlerts();
  const { id, name: savedName, email: savedEmail } = useAuth();
  const { dataService } = useAppServices();

  const [name, setName] = useState<string>(savedName || '');
  const [email, setEmail] = useState<string>(savedEmail || '');

  const handleSave = async () => {
    try {
      await dataService.updateUser(id!, { name, email });
      sendAlert({
        message: t('alert.saveSuccess') || 'Profile updated successfully!',
        severity: 'info'
      });
    } catch (err) {
      sendAlert({
        message: t('alert.saveError') || 'An error occurred while saving!',
        severity: 'error'
      });
    }
  };

  return (
    <Box>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Typography variant="h4">
          {t('title') || 'Profile Information'}
        </Typography>
        <Typography sx={{ mt: theme.spacing(1) }} variant="body1">
          {t('instructions') || 'View and edit your profile details below.'}
        </Typography>
      </Box>

      <Stack spacing={2} sx={{ maxWidth: { lg: '50%' } }}>
        <InputLabel>
          <Typography variant="subtitle1">
            {t('nameLabel') || 'Name'}
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <InputLabel>
          <Typography variant="subtitle1">
            {t('emailLabel') || 'Email'}
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size="large"
            variant="contained"
            color="info"
            onClick={handleSave}
          >
            {t('saveButton') || 'Save'}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfileInfoTab;
