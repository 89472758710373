import { ChatMessage, ChatResponseChunk, ChatOptions } from './api/models';
import { ChatApiClient } from './api/chatApiClient';
import { NdJsonParserStream } from 'util/stream';
import { Conversation } from 'util/chats';

export class ChatService {
  private chatApiClient: ChatApiClient;

  constructor(tenantId: string, apiKey: string, userId?: string) {
    this.chatApiClient = new ChatApiClient(tenantId, apiKey, userId);
  }

  async *chat(
    conversation: Conversation,
    options?: {
      threadId?: string;
      allowedTags?: string[];
      useRag?: boolean;
    }
  ): AsyncGenerator<ChatResponseChunk, void> {
    const messages = conversation.map(
      ({ message, role }) =>
        ({
          content: message,
          role
        }) as ChatMessage
    );
    const chatOptions: ChatOptions = {
      context: {
        top: 5,
        allowed_tags: options?.allowedTags ?? ['public'],
        useRag: options?.useRag
      },
      session: {
        thread_id: options?.threadId
      }
    };
    const responseBody = await this.chatApiClient.chatStream(
      messages,
      chatOptions
    );

    // Read the streamed response
    const reader = responseBody
      ?.pipeThrough(new TextDecoderStream())
      .pipeThrough(new NdJsonParserStream())
      .getReader();

    if (!reader) {
      throw new Error('No response body or body is not readable');
    }

    let value: JSON | undefined;
    let done: boolean;
    while ((({ value, done } = await reader.read()), !done)) {
      yield new Promise<ChatResponseChunk>((resolve) => {
        setTimeout(() => {
          resolve(value as unknown as ChatResponseChunk);
        }, 35);
      });
    }
  }

  async alertKnowledgeMaster(
    user: { name: string; email: string },
    transcript: { name: string; data: Blob },
    knowledgeMasterEmail?: string,
    note?: string
  ): Promise<void> {
    try {
      const file = new File([transcript.data], transcript.name, {
        type: 'text/plain'
      });
      this.chatApiClient.alert(
        user.name,
        user.email,
        file,
        knowledgeMasterEmail,
        note
      );
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error alerting knowledge master: ${error.message}`);
      throw error;
    }
  }
}
