import { PropsWithChildren, createContext } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  SilentRequest
} from '@azure/msal-browser';

import { DataService } from 'services/dataService';
import { ChatService } from 'services/chatService';
import { SecurityService } from 'services/securityService';
import { useAuth } from 'hooks/useAuth';

interface AppServiceState {
  dataService: DataService;
  chatService: ChatService;
  securityService: SecurityService;
}

const initialState: AppServiceState = {
  dataService: new DataService('', '', ''),
  chatService: new ChatService('', '', ''),
  securityService: new SecurityService('', async () => null, '')
};

export const AppServiceContext = createContext<AppServiceState>(initialState);

export const AppServiceProvider: React.FC<PropsWithChildren> = (
  props: PropsWithChildren
) => {
  const { instance } = useMsal();
  const { aadInfo, id, apiKey } = useAuth();

  const acquireToken = async (scope: string) => {
    const request: SilentRequest = {
      account: instance.getActiveAccount() ?? undefined,
      scopes: [scope]
    };

    try {
      return instance.acquireTokenSilent(request);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
      }

      throw error;
    }
  };

  const { tenantId } = aadInfo;
  const dataService = new DataService(tenantId, apiKey, id);
  const chatService = new ChatService(tenantId, apiKey, id);
  const securityService = new SecurityService(tenantId, acquireToken, id);

  return (
    <AppServiceContext.Provider
      value={{
        dataService,
        chatService,
        securityService
      }}
    >
      {props.children}
    </AppServiceContext.Provider>
  );
};
