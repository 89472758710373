import { Box, styled } from '@mui/material';
import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';

export const OpenInOfficeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  border: '2px gray ridge',
  minHeight: '40vh',
  backgroundColor:
    theme.palette.mode === 'light'
      ? 'rgb(206, 206, 206)'
      : DarkModeColorPalette.menuButtonHover,
  borderRadius: '8px',
  width: '90%'
}));

export const ConversationContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})<{ open?: boolean }>(({ theme }) => ({
  ...theme.unstable_sx({
    padding: {
      lg: '0px 100px',
      md: '10px',
      sm: '0px',
      xs: '0px'
    }
  }),
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: 1000
  }),
  width: '100%',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: 1000
        }),
        width: '54%'
      }
    }
  ]
}));

export const ChatMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  padding: '10px',
  background:
    theme.palette.mode === 'dark'
      ? DarkModeColorPalette.chatMessageBackground
      : LightModeColorPalette.chatMessageBackground
}));
