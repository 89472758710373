import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';
import { useAppTheme } from 'hooks/useAppTheme';
import AccountPopover from 'components/header/AccountPopover/AccountPopover';
import ColorModeSwitch from 'components/header/ColorModeSwitch/ColorModeSwitch';
import { useAuth } from 'hooks/useAuth';
import { AppRoles } from 'config/authConfig';
import { haveCommonElements } from 'util/arrays';

export interface HeaderProps {
  onDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { onDrawerToggle } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { toggleColorMode } = useAppTheme();
  const navigate = useNavigate();
  const { aadInfo } = useAuth();

  const handleSettingsIconClick = () => {
    navigate(ROUTES.ACCOUNT_SETTINGS_ROUTE);
  };

  /*
   * ************** Render **************
   */
  const canViewSettings = haveCommonElements(aadInfo.roles, [
    AppRoles[AppRoles.Administrator]
  ]);

  return (
    <AppBar
      color="primary"
      position="fixed"
      sx={{
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
    >
      <Toolbar sx={{ padding: '10px' }}>
        <Grid container alignItems="center">
          <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <Grid item xs>
            <Typography color="inherit" variant="h5">
              <Link
                href={ROUTES.ASK_EMELY_ROUTE}
                color="inherit"
                underline="none"
              >
                EMELY.AI
              </Link>
            </Typography>
          </Grid>

          <Grid item>
            <ColorModeSwitch
              checked={theme.palette.mode === 'dark'}
              onClick={toggleColorMode}
            />
          </Grid>

          {canViewSettings && (
            <Grid item>
              <Tooltip title="Settings">
                <IconButton color="inherit" onClick={handleSettingsIconClick}>
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Grid item sx={{ ml: 1 }}>
            <AccountPopover />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
