import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GetProfileSummaryTab from './tabs/GetProfileSummaryTab';

interface PeriscopePageProps {}

const PeriscopePage: React.FC<PeriscopePageProps> = () => {
  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('periscope');

  /*
   * ************** State Vars **************
   */
  const [selectedTab, setSelectedTab] = useState<number>(0);

  /*
   * ************** Hooks **************
   */

  /*
   * ************** Helper Functions **************
   */

  /*
   * ************** Render **************
   */
  return (
    <Box
      sx={{
        px: {
          md: theme.spacing(3),
          sm: theme.spacing(2),
          xs: theme.spacing(1)
        }
      }}
    >
      <Box
        sx={{
          mb: theme.spacing(2)
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t('title')}
          <Typography
            component="sup"
            sx={{
              fontFamily: 'Righteous',
              fontSize: '1rem',
              color: 'white',
              backgroundColor: 'primary.main',
              fontWeight: 400,
              lineHeight: 0.82,
              letterSpacing: '0.05em',
              borderRadius: '3px',
              ml: '3px'
            }}
          >
            BETA
          </Typography>
        </Typography>

        <Typography variant="h6">
          <strong>{t('subtitle')}</strong>
        </Typography>
        <Typography variant="body1">{t('description')}</Typography>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.divider
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(_event, value) => setSelectedTab(value)}
          aria-label="tabs"
          textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : ''
            }
          }}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen ? true : false}
        >
          <Tab label={t('tabs.getProfileSummary.label')} />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTab} index={0}>
        <GetProfileSummaryTab />
      </CustomTabPanel>
    </Box>
  );
};

export default PeriscopePage;
