import { PaletteMode } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';
import { ChatCitation } from 'services/api/models';
import { Converter } from 'showdown';

import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';

export type Citation = {
  index: number;
  label: string;
  fileName: string;
  page: string;
};

export type HtmlParsedAnswer = {
  answerHtml: string;
  citations: Citation[];
  showAlertButton?: boolean;
};

export const parseMessageToHtml = (
  message: string,
  dataPoints: Record<string, Partial<ChatCitation>>,
  paletteMode: PaletteMode,
  appendCitations?: boolean,
  showAlertButton?: boolean,
  onDownloadCitationClicked?: (citation: Citation) => void
): HtmlParsedAnswer => {
  const converter = new Converter();
  const citations: Citation[] = [];
  const citationRefs: Record<string, number> = {};

  // trim any whitespace from the end of the answer
  let parsedAnswer = message.replaceAll('\n', '<br>').trim();
  while (parsedAnswer.endsWith('<br>')) {
    parsedAnswer = parsedAnswer.slice(0, -4);
  }

  // Don't waste time parsing citations if we don't need them
  if (!appendCitations || showAlertButton) {
    const answerHtml = converter.makeHtml(parsedAnswer);
    return {
      answerHtml,
      citations: [],
      showAlertButton
    };
  }

  // Split the answer into parts, where the odd parts are citations
  const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
  const fragments: string[] = parts.map((part, index) => {
    if (index % 2 === 0) {
      // Even parts are just text
      return part;
    } else {
      // Odd parts are citations
      const citationChunk = dataPoints[part];

      if (!citationChunk) {
        // Return an empty string to avoid throwing an error while the citation
        // begins streaming
        return '';
      } else {
        const { title, page } = citationChunk;

        const fileName = title ?? '';
        const label = fileName.replace(/\.[^/.]+$/, '');

        const citation: Citation = {
          index: -1,
          label,
          fileName,
          page: page ?? '0'
        };
        let citationIndex: number;
        if (citationRefs[part] !== undefined) {
          // Multiple references to the same document should have the same reference number
          citationIndex = citationRefs[part];
          citation.index = citationIndex;
        } else {
          citationIndex = citations.length + 1;
          citation.index = citationIndex;
          citations.push(citation);

          citationRefs[part] = citationIndex;
        }

        const onClick = onDownloadCitationClicked
          ? () => onDownloadCitationClicked(citation)
          : undefined;

        return renderToStaticMarkup(
          <a
            style={{ cursor: 'pointer', margin: '0 1px' }}
            title={label}
            onClick={onClick}
          >
            <sup
              style={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                backgroundColor:
                  paletteMode === 'dark'
                    ? DarkModeColorPalette.navMenuBackground
                    : LightModeColorPalette.navMenuBackground
              }}
            >
              <strong>{citationIndex}</strong>
            </sup>
          </a>
        );
      }
    }
  });

  const answerHtml = converter.makeHtml(fragments.join(''));
  return {
    answerHtml,
    citations
  };
};
