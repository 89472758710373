import { AuthenticationResult } from '@azure/msal-browser';

export interface AuthorizationProvider {
  getAuthorizationHeader: (scope?: string) => Promise<Record<string, string>>;
}

export class ApiKeyAuthorizationProvider implements AuthorizationProvider {
  constructor(
    private tenantId: string,
    private apiKey: string,
    private userId?: string
  ) {}

  async getAuthorizationHeader(): Promise<Record<string, string>> {
    const header = {
      'X-TENANT-ID': this.tenantId,
      'X-API-KEY': this.apiKey
    };

    return this.userId ? { ...header, 'X-USER-ID': this.userId } : header;
  }
}

export type AcquireTokenFunction = (
  scope: string
) => Promise<AuthenticationResult | null>;

export class OAuthAuthorizationProvider implements AuthorizationProvider {
  constructor(
    private tenantId: string,
    private acquireToken: AcquireTokenFunction,
    private userId?: string
  ) {}

  async getAuthorizationHeader(scope?: string): Promise<Record<string, string>>;
  async getAuthorizationHeader(scope: string): Promise<Record<string, string>> {
    const authResult = await this.acquireToken(scope);
    if (authResult) {
      const accessToken = authResult.accessToken;
      const header = {
        'X-TENANT-ID': this.tenantId,
        Authorization: `Bearer ${accessToken}`
      };

      return this.userId ? { ...header, 'X-USER-ID': this.userId } : header;
    }

    throw new Error('Failed to acquire access token');
  }
}
