import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ShareIcon from '@mui/icons-material/Share';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { useTranslation } from 'react-i18next';
import { AccountResponse } from 'services/api/models';

export type ShareType = 'email' | 'emailMe' | 'download';

interface ChatInputProps {
  account: AccountResponse;
  onSend: (question: string) => void;
  onCopyChat: () => void;
  onShareChat: (shareType: ShareType) => void;
  onAlertKnowledgeMaster: (email: string) => void;
  onClearChat?: () => void;
  disabled?: boolean;
  showChatActions?: boolean;
  placeholder?: string;
}

const ChatInput: React.FC<ChatInputProps> = ({
  account,
  onSend,
  onCopyChat,
  onShareChat,
  onAlertKnowledgeMaster,
  onClearChat,
  disabled,
  showChatActions,
  placeholder
}: ChatInputProps) => {
  /*
   * ************** Providers **************
   */
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.input'
  });

  /*
   * ************** State Vars **************
   */
  const [message, setMessage] = useState<string>('');
  const [shareMenuAnchorEl, setShareMenuAnchorEl] =
    useState<HTMLElement | null>(null);
  const [alertKnowledgeMasterAnchorEl, setAlertKnowledgeMasterAnchorEl] =
    useState<HTMLElement | null>(null);
  const isSendDisabled = disabled || !message.trim();
  const inputRef = useRef<HTMLInputElement | null>(null);

  /*
   * ************** Hooks *********************
   */
  // Focus the input box when it is enabled
  useEffect(() => {
    if (!disabled) {
      inputRef.current?.focus();
    }
  }, [disabled]);

  /*
   * ************** Helper Functions **************
   */
  const sendQuestion = () => {
    if (isSendDisabled) {
      console.debug('Chat not sent because send is disabled');
      return;
    }

    onSend(message);
    setMessage('');
  };

  const handleMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value) {
      setMessage('');
    } else {
      setMessage(value);
    }
  };

  const handleEnterPressed = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendQuestion();
    }
  };

  const handleSendClicked = () => {
    sendQuestion();
  };

  const handleShareButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    setShareMenuAnchorEl(
      shareMenuAnchorEl === null ? event.currentTarget : null
    );
  };

  const handleShareMenuClose = () => setShareMenuAnchorEl(null);

  const handleAlertKnowledgeMasterButtonClicked = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAlertKnowledgeMasterAnchorEl(
      alertKnowledgeMasterAnchorEl === null ? event.currentTarget : null
    );
  };

  const handleAlertKnowledgeMasterMenuClose = () =>
    setAlertKnowledgeMasterAnchorEl(null);

  /*
   * ************** Render **************
   */

  return (
    <>
      <Stack useFlexGap spacing={1}>
        {/* Chat actions */}
        {showChatActions && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: '32px'
            }}
          >
            <Tooltip title={t('copyButtonTooltip')}>
              <span>
                <IconButton disabled={disabled} onClick={onCopyChat}>
                  <CopyAllIcon sx={{ height: '18px' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('shareButtonTooltip')}>
              <span>
                <IconButton
                  disabled={disabled}
                  onClick={handleShareButtonClicked}
                >
                  <ShareIcon sx={{ height: '18px' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('alertButtonTooltip')}>
              <span>
                <IconButton
                  disabled={disabled}
                  onClick={handleAlertKnowledgeMasterButtonClicked}
                >
                  <ReportProblemRoundedIcon sx={{ height: '18px' }} />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}

        {/* Chat input */}
        <Box sx={{ position: 'relative', display: 'flex' }}>
          {/* Clear chat button */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end'
            }}
          >
            <Tooltip title={t('clearChatButtonTooltip')}>
              <IconButton onClick={onClearChat}>
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <TextField
            fullWidth
            multiline
            size="small"
            disabled={disabled}
            placeholder={placeholder}
            value={message}
            onChange={handleMessageChanged}
            onKeyUp={handleEnterPressed}
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <IconButton
                    onClick={handleSendClicked}
                    disabled={isSendDisabled}
                  >
                    <SendIcon />
                  </IconButton>
                </Box>
              )
            }}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {`Emely AI Chat v${process.env.REACT_APP_VERSION}`}
          </Typography>
        </Box>
      </Stack>

      {/* Share menu */}
      <Menu
        open={!!shareMenuAnchorEl}
        anchorEl={shareMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleShareMenuClose}
      >
        <MenuItem onClick={() => onShareChat('emailMe')}>
          {t('shareButtonMenu.emailMe')}
        </MenuItem>
        <MenuItem onClick={() => onShareChat('email')}>
          {t('shareButtonMenu.sendEmail')}
        </MenuItem>
        <MenuItem onClick={() => onShareChat('download')}>
          {t('shareButtonMenu.downloadToFile')}
        </MenuItem>
      </Menu>

      {/* Alert Knowledge Master menu */}
      <Menu
        open={!!alertKnowledgeMasterAnchorEl}
        anchorEl={alertKnowledgeMasterAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleAlertKnowledgeMasterMenuClose}
      >
        <MenuItem
          onClick={() => {
            onAlertKnowledgeMaster(account.defaultKnowledgeMasterEmail);
            handleAlertKnowledgeMasterMenuClose();
          }}
        >
          {t('alertKnowledgeMasterMenu.general')}
        </MenuItem>
        {account.departments.map(({ name, knowledgeMasterEmail }) => (
          <MenuItem
            key={name}
            onClick={() => {
              onAlertKnowledgeMaster(knowledgeMasterEmail);
              handleAlertKnowledgeMasterMenuClose();
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ChatInput;
