import { ChatCitation } from 'services/api/models';

export type ConversationEntry = {
  role: 'assistant' | 'user';
  message: string;
  userEmail?: string;
  userName?: string;
  status?: string;
  citations?: Record<string, ChatCitation>;
};
export type Conversation = ConversationEntry[];

export const convertConversationToText = (
  conversation: Conversation
): string => {
  return conversation
    .map((message) => {
      const content = message.message;
      if (message.role === 'user') {
        const userName = message.userName ?? 'Unknown User';
        const email = message.userEmail ?? '';
        return `${userName} (${email}): ${content}`;
      } else if (message.role === 'assistant') {
        const citationText = Object.entries(message.citations ?? {})
          .map(([key, value]) => `${key}: ${value.title}`)
          .join('\n');
        return `Emely: ${content}\n\n[Citations]\n${citationText}`;
      }

      return '';
    })
    .join('\n\n------------------------------------------\n\n');
};

export const convertConversationToBlob = (conversation: Conversation): Blob => {
  const chatText = convertConversationToText(conversation);
  return new Blob([chatText], { type: 'text/plain' });
};

export const getFormattedDate = () => {
  const pad = (num: number) => num.toString().padStart(2, '0');

  const now = new Date();
  const year = pad(now.getFullYear());
  const month = pad(now.getMonth() + 1);
  const day = pad(now.getDate());
  const hour = pad(now.getHours());
  const minute = pad(now.getMinutes());
  const second = pad(now.getSeconds());
  return year + month + day + hour + minute + second;
};
