import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Navigator from 'components/nav/Navigator/Navigator';
import Header from 'components/header/Header/Header';
import AlertBar from 'components/AlertBar/AlertBar';

const Layout: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  /*
   * ************** State Vars *********************
   */
  const [mobileOpen, setMobileOpen] = useState(false);

  /*
   * ************** Helper Functions *********************
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 256;

  /*
   * ************** Render *********************
   */
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
      <Header onDrawerToggle={handleDrawerToggle} />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 }
          }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{
                style: { width: drawerWidth }
              }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{
              display: { sm: 'block', xs: 'none' },
              width: drawerWidth,
              flexShrink: 0
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Toolbar sx={{ mb: 4 }} />
          <AlertBar />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
