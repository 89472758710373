// --------------------------------------------
// Chat API
// --------------------------------------------
export type ChatMessage = {
  role: 'system' | 'user' | 'assistant' | 'function';
  content: string;
  userName?: string;
  userEmail?: string;
};

export type ChatRequest = {
  messages: ChatMessage[];
  stream?: boolean;
  context?: ChatRequestContext;
  session_state?: ChatSessionState;
};

export type ChatRequestContext = Record<string, any> & {
  top?: number;
  allowed_tags?: string[];
  useRag?: boolean;
};

export type ChatOptions = {
  stream?: boolean;
  context?: ChatRequestContext;
  session?: ChatSessionState;
};

export type ChatSessionState = Record<string, any> & {
  thread_id?: string;
  paused?: boolean;
};

export type ChatResponseStatus = 'OK' | 'INSUFFICIENT_EVIDENCE';

export type ChatResponse = {
  object: 'chat.completion';
  status: ChatResponseStatus;
  choices: ChatResponseChoice[];
};

export interface ChatResponseChoice {
  index: number;
  message: ChatResponseMessage;
  finish_reason: string | null;
}

export type ChatResponseChunk = {
  object: 'chat.completion.chunk';
  status?: ChatResponseStatus;
  choices: ChatResponseChunkChoice[];
};

export interface ChatResponseChunkChoice {
  index: number;
  delta: Partial<ChatResponseMessage>;
  finish_reason: string | null;
}

export type ChatResponseMessage = ChatMessage & {
  context?: Record<string, any> & {
    data_points?: Record<string, ChatCitation>;
  };
  session_state: ChatSessionState;
};

export type ChatCitation = {
  title: string;
  page: string;
};

// --------------------------------------------
// Data API - Accounts
// --------------------------------------------
export class UpdateAccountRequestBody {
  constructor(
    public name?: string,
    public defaultKnowledgeMasterEmail?: string,
    public groupTags?: GroupTag[],
    public departments?: Department[]
  ) {}
}

export type AccountResponse = {
  tenantId: string;
  name: string;
  defaultKnowledgeMasterEmail: string;
  groupTags: GroupTag[];
  departments: Department[];
};

export type GroupTag = {
  groupId: string;
  tags: string[];
};

export type Department = {
  name: string;
  knowledgeMasterEmail: string;
};

// --------------------------------------------
// Data API - Users
// --------------------------------------------
export class CreateUserRequestBody {
  constructor(
    public name: string,
    public externalId: string,
    public email?: string,
    public isServiceAccount: boolean = true
  ) {}
}

export class UpdateUserRequestBody {
  constructor(
    public name?: string,
    public email?: string,
    public isServiceAccount?: boolean
  ) {}
}

export type UserResponse = {
  id: string;
  tenantId: string;
  externalId: string;
  name: string;
  email?: string;
  isServiceAccount: boolean;
};

// --------------------------------------------
// Data API - Files
// --------------------------------------------
export type FileMetadata = {
  category?: string;
  tags?: string[];
};

export type FilesResponse = {
  files: FileData[];
};

export type FileData = {
  name: string;
  content?: string;
  tags: string[];
};

export type GetFileMetadataOptions = {
  includeContent?: boolean;
  tags?: string[];
};

// --------------------------------------------
// Data API - Q&As
// --------------------------------------------
export class CreateQnaRequestBody {
  constructor(
    public question: string,
    public answer: string,
    public tags?: string[],
    public id?: number
  ) {}
}

export type GetQnasOptions = {
  top?: number;
  skip?: number;
  tags?: string[];
};

export type QnasResponse = {
  count: number;
  qnas: CreateQnaRequestBody[];
};

// --------------------------------------------
// Security API - Groups
// --------------------------------------------
export type GroupsResponse = TenantGroup[];

export type TenantGroup = {
  id: string;
  displayName: string;
};
