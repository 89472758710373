import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import { AppRoles } from 'config/authConfig';
import { Typography } from '@mui/material';

export const ROUTES = {
  ASK_EMELY_ROUTE: '/ask-emely',
  MY_WORKSPACE_ROUTE: '/my-workspace',
  KNOWLEDGE_BASE_ROUTE: '/knowledge-base',
  PERISCOPE_ROUTE: '/periscope',
  ACCOUNT_SETTINGS_ROUTE: '/account-settings',
  PROFILE_ROUTE: '/profile'
};

export type MenuItem = {
  title: string;
  icon: JSX.Element;
  route: string;
  allowedRoles: string[];
  decoration?: JSX.Element;
};

export const navMenu: MenuItem[] = [
  {
    title: 'askEmely',
    icon: <AutoAwesomeIcon />,
    route: ROUTES.ASK_EMELY_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'myWorkspace',
    icon: <AutoAwesomeIcon />,
    route: ROUTES.MY_WORKSPACE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'knowledgeBase',
    icon: <PsychologyAltIcon />,
    route: ROUTES.KNOWLEDGE_BASE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'periscope',
    icon: <TravelExploreIcon />,
    route: ROUTES.PERISCOPE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ],
    decoration: (
      <Typography
        component="sup"
        sx={{
          fontFamily: 'Righteous',
          fontSize: '0.75rem',
          color: 'white',
          backgroundColor: 'primary.main',
          fontWeight: 400,
          lineHeight: 0.82,
          letterSpacing: '0.05em',
          borderRadius: '3px',
          ml: '3px'
        }}
      >
        BETA
      </Typography>
    )
  }
];

export default navMenu;
