import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { waveform } from 'ldrs';

import GroupLogo from '../../../assets/GroupLogo.png';
import ProfileGroup from '../../../assets/ProfileLogo.png';

import { AccountResponse } from 'services/api/models';
import { ChatMessageContainer } from 'components/ui/container';
import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';
import { Citation, HtmlParsedAnswer } from '../../util/chatMessageParser';
import ChatMessageCitation from './ChatMessageCitation';
import ChatMessageActions from './ChatMessageActions';

interface ChatMessageProps {
  account: AccountResponse;
  message: string;
  role: 'assistant' | 'user';
  htmlParsedAnswer?: HtmlParsedAnswer;
  isGenerating?: boolean;
  isThinking?: boolean;
  onDownloadCitationClicked?: (citation: Citation) => void;
  onPreviewCitationClicked?: (citation: Citation) => void;
  onAlertKnowledgeMaster?: (email: string) => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  account,
  message,
  role,
  htmlParsedAnswer,
  isGenerating,
  isThinking,
  onDownloadCitationClicked,
  onPreviewCitationClicked,
  onAlertKnowledgeMaster
}: ChatMessageProps) => {
  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.answer'
  });

  /*
   * ************** State Vars **************
   */
  const answerEndRef = useRef<HTMLDivElement | null>(null);

  const [alertKnowledgeMasterAnchorEl, setAlertKnowledgeMasterAnchorEl] =
    useState<HTMLElement | null>(null);

  /*
   * ************** Hooks **************
   */
  useEffect(() => {
    // Scroll to the bottom of the answer as it's being generated
    if (answerEndRef.current) {
      answerEndRef.current.scrollIntoView(false);
    }
  }, [message]);

  /*
   * ************** Helper Functions **************
   */
  const handleAlertKnowledgeMasterButtonClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAlertKnowledgeMasterAnchorEl(
        alertKnowledgeMasterAnchorEl === null ? event.currentTarget : null
      );
    },
    []
  );

  const handleAlertKnowledgeMasterMenuClose = useCallback(
    () => setAlertKnowledgeMasterAnchorEl(null),
    []
  );

  /*
   * ************** Render **************
   */
  waveform.register();
  if (role === 'user') {
    return (
      <>
        <ChatMessageContainer sx={{ mr: 1 }}>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <img src={ProfileGroup} alt="" />
        </Box>
      </>
    );
  } else if (htmlParsedAnswer) {
    const sanitizedHtml = DOMPurify.sanitize(htmlParsedAnswer.answerHtml);
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, mr: 1 }}>
          <img src={GroupLogo} alt="" />
        </Box>
        <ChatMessageContainer>
          {isThinking ? (
            <Box sx={{ padding: '8px' }}>
              <l-waveform
                color={
                  theme.palette.mode === 'dark'
                    ? DarkModeColorPalette.textPrimary
                    : LightModeColorPalette.textLink
                }
                stroke={3}
                size={24}
              ></l-waveform>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  borderBottom: htmlParsedAnswer.citations.length
                    ? `1px solid ${theme.palette.divider}`
                    : undefined,
                  width: '100%',
                  padding: '16px 8px'
                }}
              >
                {/* Chat response */}
                <Box
                  sx={{
                    textAlign: 'start'
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                  ></div>
                </Box>
                {htmlParsedAnswer.showAlertButton && (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleAlertKnowledgeMasterButtonClicked}
                  >
                    {t('reportToKnowledgeMaster')}
                  </Button>
                )}

                {/* Action buttons */}
                <ChatMessageActions
                  message={message}
                  copyDisabled={isGenerating}
                  emailDisabled={isGenerating}
                />
              </Box>

              {/* Citations */}
              {!!htmlParsedAnswer.citations.length && (
                <Box
                  sx={{
                    padding: '16px 8px',
                    width: '100%'
                  }}
                >
                  <Typography variant="h2">{t('citations')}</Typography>
                  <Box sx={{ mt: 2 }}>
                    {htmlParsedAnswer.citations.map((citation) => (
                      <ChatMessageCitation
                        key={citation.index}
                        citation={citation}
                        onDownloadClicked={() =>
                          onDownloadCitationClicked &&
                          onDownloadCitationClicked(citation)
                        }
                        onPreviewClicked={() =>
                          onPreviewCitationClicked &&
                          onPreviewCitationClicked(citation)
                        }
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}
        </ChatMessageContainer>

        {/* Alert Knowledge Master menu */}
        <Menu
          open={!!alertKnowledgeMasterAnchorEl}
          anchorEl={alertKnowledgeMasterAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleAlertKnowledgeMasterMenuClose}
        >
          <MenuItem
            onClick={() => {
              onAlertKnowledgeMaster &&
                onAlertKnowledgeMaster(account.defaultKnowledgeMasterEmail);
              handleAlertKnowledgeMasterMenuClose();
            }}
          >
            {t('alertKnowledgeMasterMenu.general')}
          </MenuItem>
          {account.departments.map(({ name, knowledgeMasterEmail }) => (
            <MenuItem
              key={name}
              onClick={() => {
                onAlertKnowledgeMaster &&
                  onAlertKnowledgeMaster(knowledgeMasterEmail);
                handleAlertKnowledgeMasterMenuClose();
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>

        <div style={{ scrollMarginBottom: 200 }} ref={answerEndRef} />
      </>
    );
  } else {
    return <></>;
  }
};

export default ChatMessage;
