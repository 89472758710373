import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

interface AlertKnowledgeMasterPopupProps {
  open: boolean;
  onConfirm: (note?: string) => void;
  onCancel: () => void;
}

const AlertKnowledgeMasterPopup: React.FC<AlertKnowledgeMasterPopupProps> = (
  props: AlertKnowledgeMasterPopupProps
) => {
  const { open, onConfirm, onCancel } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('modal', {
    keyPrefix: 'alertKnowledgeMaster'
  });

  /*
   * ************** Providers **************
   */
  const [note, setNote] = useState<string | undefined>();

  /*
   * ************** Render **************
   */
  return (
    <Dialog
      open={open}
      onClose={() => {
        setNote(undefined);
        onCancel();
      }}
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('message')}</Typography>
        <TextField
          value={note}
          placeholder={t('notePlaceholder')}
          onChange={(event) => setNote(event.target.value)}
          multiline
          fullWidth
          minRows={2}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          sx={{ color: theme.palette.error.main }}
          startIcon={<CloseIcon />}
          onClick={onCancel}
        >
          {t('cancelButton')}
        </Button>
        <Button
          variant="contained"
          color="success"
          startIcon={<SendIcon />}
          onClick={() => {
            onConfirm(note && note.length > 0 ? note : undefined);
            setNote(undefined);
          }}
        >
          {t('confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertKnowledgeMasterPopup;
