import React, { memo, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { AccountResponse, CreateQnaRequestBody } from 'services/api/models';
import QnaEditor from 'components/qnaEditor/QnaEditor/QnaEditor';
import { useAppServices } from 'hooks/useAppServices';
import { AlertMessage } from 'contexts/AlertContext';
import { useTranslation } from 'react-i18next';

export const QNA_TAB_TITLE = 'Q&As';

export interface QnaTabProps {
  account: AccountResponse;
  active: boolean;
  onUpdate: (queryKeys?: string[], alert?: AlertMessage) => void;
  onError: (error: Error) => void;
}

const QnaTab: React.FC<QnaTabProps> = memo((props: QnaTabProps) => {
  const { account, active, onUpdate, onError } = props;
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { dataService } = useAppServices();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna'
  });

  /*
   * ************** Hooks *********************
   */
  const addMutation = useMutation({
    mutationFn: async (qna: CreateQnaRequestBody) =>
      await dataService.uploadQna(qna),
    onSuccess: () =>
      onUpdate(['qnas', 'pages'], {
        severity: 'info',
        message: t('qnaAdded')
      }),
    onError: onError
  });

  const editMutation = useMutation({
    mutationFn: async (qna: CreateQnaRequestBody) =>
      await dataService.updateQna(qna),
    onSuccess: () =>
      onUpdate(['qnas', 'pages'], {
        severity: 'info',
        message: t('qnaUpdated')
      }),
    onError: onError
  });

  const deleteMutation = useMutation({
    mutationFn: async (qna: CreateQnaRequestBody) =>
      await dataService.deleteQna(qna),
    onSuccess: () =>
      onUpdate(['qnas', 'pages'], {
        severity: 'info',
        message: t('qnaDeleted')
      }),
    onError: onError
  });

  const isProcessing = useMemo(
    () =>
      addMutation.isLoading ||
      editMutation.isLoading ||
      deleteMutation.isLoading,
    [addMutation, editMutation, deleteMutation]
  );

  /*
   * ************** Render *********************
   */
  return (
    <Box
      sx={{
        mt: theme.spacing(1)
      }}
    >
      <QnaEditor
        account={account}
        active={active}
        isProcessing={isProcessing}
        onSave={(item, edit) =>
          edit ? editMutation.mutate(item) : addMutation.mutate(item)
        }
        onDelete={deleteMutation.mutate}
      />
    </Box>
  );
});

QnaTab.displayName = 'QnaTab';

export default QnaTab;
