import { FC, memo, useMemo, useState } from 'react';
import { Box, Divider, useTheme, Skeleton, Tabs, Tab } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useAppServices } from 'hooks/useAppServices';
import { AccountResponse } from 'services/api/models';
import DocumentUploader from 'components/documentStore/DocumentUploader/DocumentUploader';
import DocumentStoreViewer from 'components/documentStore/DocumentStoreViewer/DocumentStoreViewer';
import { getAllowedTagsForUser } from 'util/tags';
import { useAuth } from 'hooks/useAuth';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { AlertMessage } from 'contexts/AlertContext';
import { useTranslation } from 'react-i18next';

export const DOCUMENT_STORE_TAB_TITLE = 'Documents';

export const UNSTRUCTURED_FILE_TYPES = [
  '.csv',
  '.txt',
  '.html',
  '.xml',
  '.md',
  '.rtf',
  '.epub',
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.odt',
  '.bmp',
  '.jpg',
  '.jpeg',
  '.png',
  '.tiff'
];
export const STRUCTURED_FILE_TYPES = ['.json'];

interface DocumentStoreTabProps {
  account: AccountResponse;
  active: boolean;
  onUpdate: (queryKeys?: string[], alert?: AlertMessage) => void;
  onError: (error: unknown) => void;
}

const DocumentStoreTab: FC<DocumentStoreTabProps> = memo(
  (props: DocumentStoreTabProps) => {
    const { account, active, onUpdate, onError } = props;

    /*
     * ************** Providers *********************
     */
    const theme = useTheme();
    const { dataService } = useAppServices();
    const { aadInfo } = useAuth();
    const { t } = useTranslation('knowledgeBase', {
      keyPrefix: 'documentStore'
    });

    /*
     * ************** State Vars *********************
     */
    const [tab, setTab] = useState(0);

    /*
     * ************** Hooks *********************
     */
    const { data, error, isLoading, isError } = useQuery({
      queryKey: ['files'],
      queryFn: async () => {
        // Get the tags the user has permissions for
        const tags = getAllowedTagsForUser(account, aadInfo.groups);
        return dataService.getFileData(tags);
      },
      enabled: active
    });

    /*
     * ************** Helper Function(s) *********************
     */
    const handleTabChanged = (
      _event: React.SyntheticEvent,
      newValue: number
    ) => {
      setTab(newValue);
    };

    const availableTags = useMemo(
      () =>
        Array.from(
          new Set(account.groupTags.flatMap((groupTag) => groupTag.tags))
        ),
      [account]
    );

    /*
     * ************** Render *********************
     */
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width="100%"
            height={theme.spacing(60)}
          />
        </Box>
      );
    }

    if (isError) {
      onError(error);
      return <Box sx={{ display: 'flex' }}>{t('errorPage')}</Box>;
    }

    return (
      <>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.divider
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChanged}
            aria-label="fileUploadTabs"
            textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
            TabIndicatorProps={{
              sx: {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.secondary
                    : ''
              }
            }}
          >
            <Tab label={t('uploader.tabs.textAndImage')} />
            <Tab label={t('uploader.tabs.json')} />
          </Tabs>
        </Box>

        <CustomTabPanel
          justifyContent="flex-start"
          sxOverride={{ width: '100% ' }}
          value={tab}
          index={0}
        >
          <DocumentUploader
            allowedFileTypes={UNSTRUCTURED_FILE_TYPES}
            tags={availableTags}
            onUpdate={onUpdate}
            onError={onError}
          />
        </CustomTabPanel>
        <CustomTabPanel
          justifyContent="flex-start"
          sxOverride={{ width: '100% ' }}
          value={tab}
          index={1}
        >
          <DocumentUploader
            allowedFileTypes={STRUCTURED_FILE_TYPES}
            tags={availableTags}
            onUpdate={onUpdate}
            onError={onError}
          />
        </CustomTabPanel>

        <Divider />

        {active && (
          <DocumentStoreViewer
            files={data}
            tags={availableTags}
            onUpdate={onUpdate}
            onError={onError}
          />
        )}
      </>
    );
  }
);

DocumentStoreTab.displayName = 'DocumentStoreTab';

export default DocumentStoreTab;
