import {
  Box,
  Button,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import { useAlerts } from 'hooks/useAlerts';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GetProfileSummaryTabProps {}

const GetProfileSummaryTab: React.FC<GetProfileSummaryTabProps> = () => {
  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { email } = useAuth();
  const { sendAlert } = useAlerts();
  const { t } = useTranslation('periscope', {
    keyPrefix: 'tabs.getProfileSummary'
  });

  /*
   * ************** State Vars **************
   */
  const [profileUrl, setProfileUrl] = useState<string>('');

  /*
   * ************** Helper Functions **************
   */
  const handleSubmit = async () => {
    try {
      const params = new URLSearchParams();
      params.append('emailAddress', email ?? '');
      params.append('target', profileUrl);
      const response = await axios.post(
        'https://hooks.zapier.com/hooks/catch/7983388/2e4br1s/',
        undefined,
        {
          params
        }
      );

      if (response.status === 200) {
        sendAlert({
          message:
            'Analyzing profile and sending you an email with the results!',
          severity: 'info'
        });
      } else {
        sendAlert({
          message:
            'There was an unexpected error with your request. Please try again later',
          severity: 'error'
        });
      }
    } catch (_error: unknown) {
      sendAlert({
        message: (_error as Error).message,
        severity: 'error'
      });
    } finally {
      setProfileUrl('');
    }
  };

  /*
   * ************** Render **************
   */
  return (
    <>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Typography variant="h4">{t('title')}</Typography>
        <Typography sx={{ mt: theme.spacing(1) }} variant="body1">
          {t('instructions')}
        </Typography>
      </Box>

      <Stack sx={{ maxWidth: { lg: '50%' } }}>
        <InputLabel
          sx={{
            mb: 1,
            color: theme.palette.text.primary
          }}
        >
          <Typography variant="subtitle1">{t('inputLabel')}</Typography>
        </InputLabel>
        <TextField
          fullWidth
          value={profileUrl}
          onChange={(event) => setProfileUrl(event.target.value)}
        />
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            {t('submitButton')}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default GetProfileSummaryTab;
