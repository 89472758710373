import { memo, useRef, useState } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import ErrorChatMessage from 'components/chat/ErrorChatMessage/ErrorChatMessage';
import ChatInput, { ShareType } from 'components/chat/ChatInput/ChatInput';
import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';
import { useAppServices } from 'hooks/useAppServices';
import { useAlerts } from 'hooks/useAlerts';
import { useAuth } from 'hooks/useAuth';
import { downloadFile } from 'util/files';
import { getAllowedTagsForUser } from 'util/tags';
import {
  Citation,
  parseMessageToHtml
} from 'components/util/chatMessageParser';
import {
  Conversation,
  ConversationEntry,
  convertConversationToBlob,
  convertConversationToText,
  getFormattedDate
} from 'util/chats';
import ChatMessage from 'components/chat/ChatMessage/ChatMessage';
import AlertKnowledgeMasterPopup from 'components/modal/AlertKnowledgeMasterPopup/AlertKnowledgeMasterPopup';

interface ChatTabProps {
  docChat?: boolean;
  onPreviewCitation: (citation: Citation) => void;
}

const ChatTab: React.FC<ChatTabProps> = memo((props: ChatTabProps) => {
  const { docChat, onPreviewCitation } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { chatService, dataService } = useAppServices();
  const { name, email: userEmail, aadInfo } = useAuth();
  const { sendAlert } = useAlerts();
  const { t } = useTranslation('chat', {
    keyPrefix: docChat ? 'docChat' : 'chat'
  });

  /*
   * ************** State Vars *********************
   */
  const lastMessageRef = useRef<string>('');
  const knowledgeMasterEmailRef = useRef<string | undefined>();

  const [error, setError] = useState<Error>();
  const [conversation, setConversation] = useState<Conversation>([]);
  const [isThinking, setThinking] = useState(false);
  const [isGeneratingResponse, setGeneratingResponse] =
    useState<boolean>(false);
  const [alertKnowledgeMasterPopupOpen, setAlertKnowledgeMasterPopupOpen] =
    useState(false);

  /*
   * ************** Hooks *********************
   */
  const { data: account } = useQuery({
    queryKey: ['account'],
    queryFn: () => dataService.getAccount(),
    staleTime: 1800000 // 30 minutes
  });

  /*
   * ************** Helper Functions *********************
   */
  const handleSendQuestion = async (entry: ConversationEntry) => {
    if (!account) {
      console.error('Cannot send question, no account detected');
      setError(new Error(t('message.noAccount')));
    } else {
      lastMessageRef.current = entry.message;

      error && setError(undefined);

      try {
        const messages = [...conversation, entry];

        // Get the tags the user has permissions for
        const allowedTags = getAllowedTagsForUser(account, aadInfo.groups);

        // Stream the chat response
        const useRag = Boolean(docChat);
        const answerStream = chatService.chat(messages, {
          allowedTags,
          useRag
        });

        const nextResponse: ConversationEntry = {
          role: 'assistant',
          message: '',
          status: 'OK'
        };
        setConversation([...messages, nextResponse]);
        setGeneratingResponse(true);
        setThinking(true);

        for await (const chunk of answerStream) {
          const { content, context } = chunk.choices[0].delta;
          const status = chunk.status;

          const chunkValue = content ?? '';
          if (chunkValue === '') {
            // Skip chunks with no content
            continue;
          } else {
            // Gather the chunks one-by-one into a single response
            nextResponse.status = status ?? 'OK';

            const nextMessage =
              status === 'INSUFFICIENT_EVIDENCE'
                ? `I'm sorry, I wasn't able to find an answer in your knowledge base. Please rephrase your message or use the buttons below to report this behavior to the knowledge master.`
                : chunkValue;

            nextResponse.message = nextMessage;
            nextResponse.citations = context?.data_points;
          }

          setConversation((prevConversation) =>
            prevConversation.map((entry, i) => {
              if (i === prevConversation.length - 1) {
                return {
                  ...nextResponse
                };
              }

              return entry;
            })
          );
          setThinking(false);
        }
      } catch (e: unknown) {
        const error = e as Error;
        console.error(error);
        setError(error);
        sendAlert({
          severity: 'error',
          message: t('alert.chatError')
        });
      } finally {
        setGeneratingResponse(false);
      }
    }
  };

  const handleClearChat = () => {
    lastMessageRef.current = '';
    error && setError(undefined);
    setConversation([]);
    //setSelectedCitation(undefined);
  };

  const handleDownloadCitation = async (citation: Citation) => {
    const { fileName } = citation;
    try {
      const file = await dataService.fetchFile(fileName);
      downloadFile(fileName, file);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(error);
      setError(error);
      sendAlert({
        severity: 'error',
        message: t('alert.downloadError')
      });
    }
  };

  const handleCopyChat = () => {
    const chatText = convertConversationToText(conversation);
    navigator.clipboard.writeText(chatText);
    sendAlert({
      severity: 'info',
      message: t('alert.conversationCopied')
    });
  };

  const handleShareChat = async (shareType: ShareType) => {
    const data = convertConversationToBlob(conversation);
    const fileName = `emely-ai-chat-log_${getFormattedDate()}.txt`;
    switch (shareType) {
      case 'emailMe': {
        await dataService.emailChatTranscript(userEmail!, data, fileName);
        sendAlert({
          severity: 'info',
          message: t('alert.emailSent')
        });
        break;
      }
      case 'email': {
        // Use a mailto link
        const chatText = convertConversationToText(conversation);
        window.open(`mailto:?body=${encodeURIComponent(chatText)}`);

        sendAlert({
          severity: 'info',
          message: t('alert.emailSent')
        });
        break;
      }
      case 'download': {
        // Create a download link and click it
        const element = document.createElement('a');
        element.href = URL.createObjectURL(data);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
      }
    }
  };

  const openAlertKnowledgeMasterPopup = (knowledgeMasterEmail: string) => {
    knowledgeMasterEmailRef.current = knowledgeMasterEmail;
    setAlertKnowledgeMasterPopupOpen(true);
  };

  const handleAlertKnowledgeMaster = async (
    knowledgeMasterEmail?: string,
    note?: string
  ) => {
    const data = convertConversationToBlob(conversation);
    const fileName = `emely-ai-chat-log_${getFormattedDate()}.txt`;
    try {
      await chatService.alertKnowledgeMaster(
        {
          name: name!,
          email: userEmail!
        },
        {
          name: fileName,
          data
        },
        knowledgeMasterEmail,
        note
      );

      sendAlert({
        severity: 'info',
        message: t('alert.knowledgeMasterAlerted')
      });
      setAlertKnowledgeMasterPopupOpen(false);
    } catch (e) {
      sendAlert({
        severity: 'error',
        message: t('alert.knowledgeMasterAlertError')
      });
    }
  };

  /*
   * ************** Render *********************
   */
  if (!account) {
    return <></>;
  }

  return (
    <>
      {!lastMessageRef.current ? (
        <>
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bolder' }}>
              {t('title', { name: name!.split(' ')[0] })}
            </Typography>
          </Box>
          <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              padding: '20px',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              mt: 4
            }}
          >
            <Typography variant="body1">
              {docChat ? (
                <Trans i18nKey="instructions" t={t}>
                  Enter a message into the box below to start chatting with
                  Emely! This is DocChat, so your company documents will be
                  queried for answers. To use the basic chat, go to{' '}
                  <Link href={ROUTES.MY_WORKSPACE_ROUTE}>My Workspace</Link>.
                </Trans>
              ) : (
                <Trans i18nKey="instructions" t={t}>
                  Enter a message into the box below to start chatting with
                  Emely! This is the basic version of the chat experience, so
                  none of your company documents will be queried for answers. To
                  chat with your company documents, go to{' '}
                  <Link href={ROUTES.ASK_EMELY_ROUTE}>Ask Emely</Link>.
                </Trans>
              )}
            </Typography>
          </Box>
        </>
      ) : (
        <Box>
          {conversation.map((entry, i) => {
            const { message, status, role, citations } = entry;
            const parsedMessage =
              role === 'assistant'
                ? parseMessageToHtml(
                    message,
                    citations ?? {},
                    theme.palette.mode,
                    docChat,
                    status === 'INSUFFICIENT_EVIDENCE',
                    handleDownloadCitation
                  )
                : undefined;
            if (i === conversation.length - 1 && error) {
              return (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mt: '25px'
                  }}
                >
                  <ErrorChatMessage
                    error={error.message}
                    onRetry={() =>
                      handleSendQuestion({
                        role: 'user',
                        message: lastMessageRef.current,
                        userName: name,
                        userEmail: userEmail
                      })
                    }
                  />
                </Box>
              );
            } else {
              return (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    justifyContent: role === 'user' ? 'flex-end' : 'flex-start',
                    mb: 3
                  }}
                >
                  <ChatMessage
                    account={account}
                    message={message}
                    role={role}
                    htmlParsedAnswer={parsedMessage}
                    isGenerating={isGeneratingResponse}
                    isThinking={i === conversation.length - 1 && isThinking}
                    onAlertKnowledgeMaster={openAlertKnowledgeMasterPopup}
                    onDownloadCitationClicked={handleDownloadCitation}
                    onPreviewCitationClicked={onPreviewCitation}
                  />
                </Box>
              );
            }
          })}
        </Box>
      )}

      <AlertKnowledgeMasterPopup
        open={alertKnowledgeMasterPopupOpen}
        onConfirm={(note) =>
          handleAlertKnowledgeMaster(knowledgeMasterEmailRef.current, note)
        }
        onCancel={() => {
          knowledgeMasterEmailRef.current = undefined;
          setAlertKnowledgeMasterPopupOpen(false);
        }}
      />

      <Box sx={{ mt: '20px' }}>
        <ChatInput
          account={account}
          placeholder={
            isGeneratingResponse
              ? t('message.generating')
              : t('message.enterMessage')
          }
          disabled={isGeneratingResponse}
          showChatActions={conversation.length > 0}
          onSend={(q) =>
            handleSendQuestion({
              role: 'user',
              message: q,
              userName: name,
              userEmail: userEmail
            })
          }
          onCopyChat={handleCopyChat}
          onShareChat={handleShareChat}
          onClearChat={handleClearChat}
          onAlertKnowledgeMaster={openAlertKnowledgeMasterPopup}
        />
      </Box>
    </>
  );
});

ChatTab.displayName = 'ChatTab';

export default ChatTab;
