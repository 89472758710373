import { Box, SxProps, Theme, useTheme } from '@mui/material';

export type CustomTabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  sxOverride?: SxProps<Theme>;
  justifyContent?: string;
};

const CustomTabPanel: React.FC<CustomTabPanelProps> = (
  props: CustomTabPanelProps
) => {
  const { children, value, index, sxOverride, justifyContent } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: value === index ? 'flex' : 'none',
        justifyContent: justifyContent ?? 'center'
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          padding: '5px',
          mt: 4,
          width: {
            xl: '70%',
            lg: '80%',
            md: '90%',
            sm: '100%'
          },
          ...sxOverride
        }}
      >
        <div role="tabpanel">
          <Box sx={{ p: 3 }}>{children}</Box>
        </div>
      </Box>
    </Box>
  );
};

export default CustomTabPanel;
