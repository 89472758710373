import { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import ChatTab from './tabs/ChatTab';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { useTranslation } from 'react-i18next';
import { ConversationContainer } from 'components/ui/container';
import PreviewCitationPanel from './tabs/PreviewCitationPanel';
import { Citation } from 'components/util/chatMessageParser';

interface ChatPageProps {
  docChat?: boolean;
}

const ChatPage: React.FC<ChatPageProps> = (props: ChatPageProps) => {
  const { docChat } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('chat');

  /*
   * ************** State Vars *********************
   */
  const [value, setValue] = useState(0);
  const [selectedCitation, setSelectedCitation] = useState<
    Citation | undefined
  >();

  /*
   * ************** Hooks *********************
   */

  /*
   * ************** Helper Functions *********************
   */
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handlePreviewCitation = (citation: Citation) => {
    setSelectedCitation(citation);
  };

  const handlePreviewPanelClosed = () => {
    setSelectedCitation(undefined);
  };

  /*
   * ************** Render *********************
   */
  return (
    <>
      <Box sx={{ padding: theme.spacing(3) }}>
        <Box
          sx={{
            mb: theme.spacing(2)
          }}
        >
          <Typography variant="h2">{t('title')}</Typography>
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.divider
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
            TabIndicatorProps={{
              sx: {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.secondary
                    : ''
              }
            }}
            variant={isSmallScreen ? 'scrollable' : 'standard'}
            scrollButtons={isSmallScreen ? true : false}
          >
            <Tab label={t('tabs.chat')} />
          </Tabs>
        </Box>
        <ConversationContainer open={!!selectedCitation}>
          <CustomTabPanel
            sxOverride={{
              width: selectedCitation
                ? '100%'
                : {
                    xl: '70%',
                    lg: '80%',
                    md: '90%',
                    sm: '100%'
                  }
            }}
            value={value}
            index={0}
          >
            <ChatTab
              docChat={docChat}
              onPreviewCitation={handlePreviewCitation}
            />
          </CustomTabPanel>
        </ConversationContainer>
        <PreviewCitationPanel
          citation={selectedCitation}
          open={!!selectedCitation}
          onClose={handlePreviewPanelClosed}
        />
      </Box>
    </>
  );
};

export default ChatPage;
