import {
  InteractionRequiredAuthError,
  SilentRequest
} from '@azure/msal-browser';
import { TokenClaims } from '@azure/msal-common';
import { useAccount, useMsal } from '@azure/msal-react';
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState
} from 'react';

import { SecurityService } from 'services/securityService';
import { getRoleName } from 'config/authConfig';
import { DataService } from 'services/dataService';
import { UserResponse } from 'services/api/models';

type AADUserInfo = {
  tenantId: string;
  objectId: string;
  name: string;
  email: string;
  roles: string[];
  groups: string[];
};

interface AuthContextState {
  loading: boolean;
  aadInfo: AADUserInfo;
  apiKey: string;
  fetchApiKey: (tenantId: string) => Promise<void>;
  fetchUser: () => Promise<void>;
  registerUser: (name: string, email: string) => Promise<void>;
  id?: string;
  name?: string;
  email?: string;
}

const defaultState: AuthContextState = {
  loading: true,
  aadInfo: {
    tenantId: '',
    objectId: '',
    name: '',
    email: '',
    roles: [],
    groups: []
  },
  apiKey: '',
  fetchApiKey: async () => {},
  fetchUser: async () => {},
  registerUser: async () => {}
};

export const AuthContext = createContext<AuthContextState>(defaultState);

type IdTokenClaims =
  | (TokenClaims & {
      [key: string]: unknown;
    })
  | undefined;

const getRolesFromIdToken = (tokenClaims: IdTokenClaims): string[] => {
  if (!tokenClaims?.roles) return [];

  return tokenClaims.roles;
};

const getGroupsFromIdToken = (tokenClaims: IdTokenClaims): string[] => {
  if (!tokenClaims?.groups) return [];

  return tokenClaims.groups as string[];
};

const getObjectIdFromIdToken = (tokenClaims: IdTokenClaims): string => {
  if (!tokenClaims?.oid) return '';

  return tokenClaims.oid;
};

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const account = useAccount();
  const { instance } = useMsal();

  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState('');
  const [user, setUser] = useState<UserResponse | undefined>();

  const tenantId = account?.tenantId ?? '';
  const aadUserName = account?.name ?? '';
  const aadEmail = account?.username ?? '';
  const userGroups = getGroupsFromIdToken(account?.idTokenClaims);
  const userObjectId = getObjectIdFromIdToken(account?.idTokenClaims);

  // Get roles from ID token. If there are none assigned, default role
  // is 'User'
  let userRoles = getRolesFromIdToken(account?.idTokenClaims).map(
    (role) => getRoleName(role) ?? ''
  );
  if (userRoles.length === 0) {
    userRoles = ['User'];
  }

  const acquireToken = async (scope: string) => {
    const request: SilentRequest = {
      account: instance.getActiveAccount() ?? undefined,
      scopes: [scope]
    };

    try {
      return instance.acquireTokenSilent(request);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
      }

      throw error;
    }
  };

  const securityService = new SecurityService(tenantId, acquireToken);
  const fetchApiKey = async (tenantId: string) => {
    const apiKey = await securityService.getApiKey(tenantId);
    setApiKey(apiKey);
  };

  const dataService = new DataService(tenantId, apiKey);
  const fetchUser = async () => {
    const user = await dataService.getUserByExternalId(userObjectId);
    setUser(user);
  };

  const registerUser = async (name: string, email: string) => {
    setLoading(true);
    try {
      await dataService.createUser(name, userObjectId, email, false);
      await fetchUser();
    } catch (err) {
      // ...handle errors...
    }
    setLoading(false);
  };

  useEffect(() => {
    if (account) {
      (async () => {
        setLoading(true);
        try {
          await fetchApiKey(tenantId);
          await fetchUser();
        } catch (err) {
          // ...handle errors...
        }
        setLoading(false);
      })();
    }
  }, [account]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        aadInfo: {
          tenantId,
          objectId: userObjectId,
          name: aadUserName,
          email: aadEmail,
          roles: userRoles,
          groups: userGroups
        },
        apiKey,
        fetchApiKey,
        fetchUser,
        registerUser,
        id: user?.id,
        name: user?.name,
        email: user?.email
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
