import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';

import { useAlerts } from 'hooks/useAlerts';

interface ChatMessageActionsProps {
  message: string;
  copyDisabled?: boolean;
  emailDisabled?: boolean;
}

const ChatMessageActions: React.FC<ChatMessageActionsProps> = (
  props: ChatMessageActionsProps
) => {
  const { message, copyDisabled, emailDisabled } = props;

  /*
   * ************** Providers **************
   */
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.answer'
  });
  const { sendAlert } = useAlerts();

  /*
   * ************** State Vars **************
   */

  /*
   * ************** Hooks **************
   */

  /*
   * ************** Helper Functions **************
   */
  const handleCopyButtonClicked = () => {
    navigator.clipboard.writeText(message);
    sendAlert({
      severity: 'info',
      message: t('copySuccessMessage')
    });
  };

  /*
   * ************** Render **************
   */
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
        <Tooltip title={t('copyButtonTooltip')}>
          <span>
            <IconButton
              disabled={copyDisabled}
              onClick={handleCopyButtonClicked}
            >
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('emailButtonTooltip')}>
          <span>
            <IconButton
              disabled={emailDisabled}
              href={`mailto:?body=${encodeURIComponent(message)}`}
            >
              <EmailIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default ChatMessageActions;
