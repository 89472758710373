import { AcquireTokenFunction } from './api/authorizationProvider';
import { GroupsResponse } from './api/models';
import { SecurityApiClient } from './api/securityApiClient';

export class SecurityService {
  private securityApiClient: SecurityApiClient;

  constructor(
    tenantId: string,
    acquireToken: AcquireTokenFunction,
    userId?: string
  ) {
    this.securityApiClient = new SecurityApiClient(
      tenantId,
      acquireToken,
      userId
    );
  }

  async getApiKey(tenantId: string): Promise<string> {
    try {
      const apiKey = await this.securityApiClient.getApiKey(tenantId);
      if (!apiKey) {
        throw new Error('API key not found');
      }

      return apiKey;
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error fetching API key: ${error.message}`);
      throw error;
    }
  }

  async generateApiKey(tenantId: string): Promise<string> {
    try {
      return this.securityApiClient.generateApiKey(tenantId);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error generating API key: ${error.message}`);
      throw error;
    }
  }

  async getTenantSecurityGroups(tenantId: string): Promise<GroupsResponse> {
    try {
      const groups =
        await this.securityApiClient.getTenantSecurityGroups(tenantId);
      return groups ?? [];
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error generating API key: ${error.message}`);
      throw error;
    }
  }
}
