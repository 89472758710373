import {
  IconButton,
  Avatar,
  Popover,
  Typography,
  useTheme,
  Divider,
  MenuItem,
  Box
} from '@mui/material';
import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';

interface AccountPopoverProps {}

const AccountPopover: React.FC<AccountPopoverProps> = () => {
  /*
   * ************** Providers **************
   */
  const { instance } = useMsal();
  const { name, aadInfo } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('header', {
    keyPrefix: 'accountMenu'
  });

  /*
   * ************** State Vars **************
   */
  const [open, setOpen] = useState<(EventTarget & Element) | undefined>(
    undefined
  );

  /*
   * ************** Helper Functions **************
   */
  const handleOpen = (event: React.MouseEvent) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleProfileClicked = () => {
    handleClose();
    navigate(ROUTES.PROFILE_ROUTE);
  };

  const handleLogout = async () => {
    await instance.logoutRedirect();
  };

  /*
   * ************** Render **************
   */
  return (
    <>
      <IconButton onClick={handleOpen} color="inherit" sx={{ p: 0 }}>
        <Avatar alt="My Avatar">
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1,
              ml: 0.75
            }
          }
        }}
      >
        <MenuItem onClick={handleProfileClicked}>
          <Avatar />
          <Box sx={{ mx: 1.5 }}>
            <Typography variant="subtitle1" noWrap>
              {name}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.secondary }}
              noWrap
            >
              {aadInfo.roles[0]}
            </Typography>
          </Box>
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: 'body2', color: theme.palette.error.main, py: 1.5 }}
        >
          {t('logOutButton')}
        </MenuItem>
      </Popover>
    </>
  );
};

export default AccountPopover;
