import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { Citation } from '../../util/chatMessageParser';
import { useTranslation } from 'react-i18next';

interface ChatMessageCitationProps {
  citation: Citation;
  onDownloadClicked: () => void;
  onPreviewClicked: () => void;
}

const ChatMessageCitation: React.FC<ChatMessageCitationProps> = (
  props: ChatMessageCitationProps
) => {
  const { onPreviewClicked, onDownloadClicked } = props;
  const { index, label, fileName, page } = props.citation;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.answer.citation'
  });

  /*
   * ************** State Vars **************
   */

  /*
   * ************** Hooks **************
   */

  /*
   * ************** Helper Functions **************
   */
  const handleTitleClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onDownloadClicked();
  };

  /*
   * ************** Render **************
   */
  return (
    <Accordion key={index}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3">
          <Link underline="hover" onClick={handleTitleClicked}>
            <sup>{`[${index}] `}</sup>
            {label} <OpenInNewIcon fontSize="small" />
          </Link>
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
      >
        <Grid container columns={24} sx={{ mt: 1 }}>
          <Grid xs={4} sm={2} md={1}>
            <InsertDriveFileIcon color="disabled" />
          </Grid>
          <Grid xs={20} sm={22} md={23}>
            <Typography sx={{ fontWeight: 0 }}>{`${t(
              'fileName'
            )}: ${fileName}`}</Typography>
          </Grid>

          <Grid xs={4} sm={2} md={1}>
            <FindInPageIcon color="disabled" />
          </Grid>
          <Grid xs={20} sm={22} md={23}>
            <Typography>{`${t('pageNumber')}: ${page}`}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>

      <AccordionActions>
        <Button variant="contained" color="info" onClick={onPreviewClicked}>
          {t('previewButton')}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default ChatMessageCitation;
