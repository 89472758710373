import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import ProfileInfoTab from './tabs/ProfileInfoTab';

interface ProfilePageProps {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('profile');

  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <Box
      sx={{
        px: {
          md: theme.spacing(3),
          sm: theme.spacing(2),
          xs: theme.spacing(1)
        }
      }}
    >
      <Box
        sx={{
          mb: theme.spacing(2)
        }}
      >
        <Typography variant="h2">{t('title')}</Typography>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.divider
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(_event, value) => setSelectedTab(value)}
          aria-label="tabs"
          textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : ''
            }
          }}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen ? true : false}
        >
          <Tab label={t('tabs.profileInfo.label')} />
        </Tabs>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          padding: '5px',
          mt: 4
        }}
      >
        <CustomTabPanel value={selectedTab} index={0}>
          <ProfileInfoTab />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default ProfilePage;
